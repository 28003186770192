<template>
  <header class="ishorizontal-topbar">
    <nav class="navbar navbar-expand-lg navbar-light bg-white">
      <div class="container-fluid">
        <div class="d-flex">
          <div class="navbar-brand-box px-2">
            <span class="skeleton-box" style="height: 32px; width: 150px" v-if="isLoading"></span>
            <router-link :to="'/' + $route.params.slug" v-else class="logo logo-dark">
              <span class="logo-sm">
                <img :src="company?.imageMobileUrl ? company.imageMobileUrl : ''" alt="Logo Mobile" height="32"/>
              </span>
              <span class="logo-lg">
                <img :src="company?.imageUrl ? company.imageUrl : ''" alt="Logo" height="32"/>
              </span>
            </router-link>
          </div>

          <div class="collapse navbar-collapse d-none d-lg-flex">
            <ul class="navbar-nav">
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="hamburger-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                  <span data-key="t-categorias" class="h5 text-muted px-2">Categorias</span>
                  <div class="arrow-down"></div>
                </a>

                <div
                  class="dropdown-menu mega-dropdown-menu px-2 dropdown-mega-menu-xl"
                  aria-labelledby="topnav-uielement"
                >
                  <div class="d-flex p-lg-0 flex-wrap">
                    <span class="skeleton-box col-3 m-1" v-if="isLoading"></span>
                    <span class="skeleton-box col-3 m-1" v-if="isLoading"></span>
                    <span class="skeleton-box col-3 m-1" v-if="isLoading"></span>
                    <div v-else v-for="category in categories" :key="category._id" class="col-3 text-center">
                      <div
                        :class="`dropdown-item side-nav-link-ref rounded cursor-pointer text-${company?.color ? company.color : 'primary'}`"
                        @click="defineCurrentCategory(category._id, category.name)"
                      >
                        {{ category.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <button
            class="btn btn-sm px-1 font-size-16 d-lg-none header-item"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fa fa-fw fa-bars"></i>
          </button>
        </div>
        <div class="d-flex">
          <div class="d-flex justify-content-center align-items-center cart-container mt-1">
            <div class="">
              <a href="#">
                <i class="mdi mdi-cart-outline font-size-26"></i>
                <span 
                  v-if="$store.getters.cartItemCount > 0" 
                  :class="!isAuthenticated ? 'cart-badge': 'cart-badge cart-badge-logged'"
                >
                  {{ $store.getters.cartItemCount }}
                </span>
              </a>
              <div v-if="$store.getters.cartItemCount > 0" class="dropdown-content">

                <div v-for="item in $store.state.cart" :key="item._id">
                  <div class="d-flex justify-content-between align-items-center dropdown-item">
                    <img :src="item.imageUrl" class="rounded col-2" alt="image-item-cart" style="max-height: 50px; max-width: 60px;"/>
                    <span class="item-cart-title col-7">{{ item.title }}</span>
                    <span class="col-3" style="text-align: end">
                      <b>{{ item.promoPrice ? $utils.formatMoney(item.promoPrice, false) : $utils.formatMoney(item.price, false) }}</b>(x{{ item.qtd }})
                    </span>
                  </div>
                </div>
                <hr/>
                <div class="d-flex justify-content-between">
                  <b>SubTotal</b>
                  <span class="text-primary h5">{{ $utils.formatMoney($store.getters.cartTotal, false) }}</span>
                </div>
                
                <div class="d-flex justify-content-end mt-4">
                  <button :class="`btn btn-md btn-rounded waves-effect waves-light btn-${company.color ? company.color : 'primary'}`">
                    <span>Ir para o Carrinho</span>
                  </button>
                </div>
                
              </div>
            </div>
          </div>

          <div class="dropdown d-inline-block">
            <button
              type="button"
              class="btn header-item user text-start d-flex align-items-center"
              id="page-header-user-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              v-if="isAuthenticated"
            >
              <div class="avatar">
                <span class="skeleton-box rounded-circle avatar-title" style="height: 40px" v-if="isLoading"></span>

                <span
                  v-else
                  :class="`avatar-title rounded-circle text-white font-size-18 bg-${company?.color ? company.color : 'primary'}`"
                >
                  {{ user?.name[0] }}
                </span>
              </div>
            </button>
            <div
              class="dropdown-menu dropdown-menu-end pt-0"
              v-if="isAuthenticated"
            >
              <div class="p-3 border-bottom">
                <h6 class="mb-0">{{ user?.name }}</h6>
                <p class="mb-0 font-size-11 text-muted">{{ user?.email }}</p>
              </div>

              <router-link class="dropdown-item" :to="`/${$route.params.slug}/profile`">
                <i class="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i>
                <span class="align-middle">Perfil</span>
              </router-link>

              <router-link class="dropdown-item" :to="`/${$route.params.slug}/company`" v-if="user?.type === 1">
                <i class="mdi mdi-domain text-muted font-size-16 align-middle me-1"></i>
                <span class="align-middle">Empresa</span>
              </router-link>

              <router-link class="dropdown-item" :to="{name: 'companyProducts', params: { slug: $route.params.slug }}">
                <i class="mdi mdi-gift-outline text-muted font-size-16 align-middle me-1"></i>
                <span class="align-middle">Produtos</span>
              </router-link>

              <router-link 
                class="dropdown-item"
                :to="{
                  name: 'companyCategories',
                  params: {
                    slug: $route.params.slug,
                    categories: JSON.stringify(this.categories),
                  }
                }"
                v-if="user?.type === 1"
              >
                <i class="mdi mdi-folder-multiple-outline text-muted font-size-16 align-middle me-1"></i>
                <span class="align-middle">Categorias</span>
              </router-link>

              <router-link class="dropdown-item" :to="`/${$route.params.slug}/users`" v-if="user?.type === 1">
                <i class="mdi mdi-account-multiple text-muted font-size-16 align-middle me-1"></i>
                <span class="align-middle">Usuários</span>
              </router-link>

              <div class="dropdown-divider"></div>

              <a class="dropdown-item d-flex align-items-center" href="#" @click="onLogout">
                <i class="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i>
                <span class="align-middle">Sair</span>
              </a>
            </div>

            <span class="skeleton-box rounded-circle avatar-title" style="height: 40px" v-if="isLoading && !isAuthenticated"></span>
            <button
              v-if="!isAuthenticated && !isLoading"
              type="button"
              :class="`btn btn-rounded waves-effect waves-light dropdown-toggle mx-3 btn-${company?.color ? company.color : 'primary'}`"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Entre ou Cadastre-se
              <i class="mdi mdi-chevron-down ms-1"></i>
            </button>
            <div class="dropdown-menu">
              <router-link class="dropdown-item" :to="`/${$route.params.slug}/login`">
                <i class="mdi mdi-account-check text-muted font-size-16 align-middle me-1"></i>
                <span class="align-middle">Entrar</span>
              </router-link>

              <router-link class="dropdown-item" :to="`/${$route.params.slug}/register`">
                <i class="mdi mdi-account-edit text-muted font-size-16 align-middle me-1"></i>
                <span class="align-middle">Cadastrar</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="d-lg-none">
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMobile"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="hamburger-icon mx-2">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <span data-key="t-categorias">Categorias</span>
                <div class="arrow-down"></div>
              </a>

              <div
                class="dropdown-menu mega-dropdown-menu px-2 dropdown-mega-menu-lg"
                aria-labelledby="topnav-uielement"
              >
                <div class="ps-2">
                  <div class="row">
                    <span class="skeleton-box col-3 m-1" v-if="isLoading"></span>
                    <span class="skeleton-box col-3 m-1" v-if="isLoading"></span>
                    <span class="skeleton-box col-3 m-1" v-if="isLoading"></span>
                    <div v-else v-for="category in categories" :key="category._id" class="col-12">
                      <div
                        :class="`dropdown-item side-nav-link-ref rounded cursor-pointer text-${company?.color ? company.color : 'primary'}`"
                        @click="defineCurrentCategory(category._id, category.name)"
                      >
                        {{ category.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { layoutMethods, layoutComputed } from "@/state/helpers";
import { mapActions, mapState } from "vuex";

export default {
  props: {
    width: {
      type: String,
      required: true,
    },
    pagetitle: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    layoutscroll: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      company: JSON.parse(localStorage.getItem("company")),
      categories: [],
      user: this.$auth.getLoggedUser(),
    };
  },
  components: {},
  computed: {
    ...layoutComputed,
    ...mapState(["isAuthenticated", "isLoading"]),
  },
  async mounted() {
    if (!this.company) {
      this.company = await this.$utils.getCompanyBySlug(
        this.$route.params.slug
      );
    }

    if (this.company && this.company._id) {
      const categories = await this.$utils.getByCompany(
        "category",
        this.company._id
      );
      if (categories) {
        this.categories = categories;
      }
    }
  },
  watch: {
    //async company(newVal, oldVal) {
    //  if (newVal && newVal !== oldVal && newVal._id) {
    //    const categories = await this.$utils.getCategoriesByCompany(newVal._id);
    //    if (categories) {
    //      this.categories = categories;
    //    }
    //  }
    //},
  },
  methods: {
    ...mapActions(["setCurrentCategory", "setCurrentCategoryName"]),
    ...layoutMethods,
    defineCurrentCategory(idCategory, nameCategory) {
      if (this.$route.name != "companyPrincipal") {
        this.$router.push({ name: "companyPrincipal" });
        this.setCurrentCategory(idCategory);
        this.setCurrentCategoryName(nameCategory);
      } else {
        this.setCurrentCategory(idCategory);
        this.setCurrentCategoryName(nameCategory);
      }
    },
    onLogout() {
      this.$auth.logout(this.$route.params.slug, false);
    },
  },
};
</script>

<style scoped>
  .cart-container {
    position: relative;
    display: inline-block;
  }

  .cart-badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: rgb(238, 60, 60);
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 10px;
    font-weight: bold;
  }

  .cart-badge-logged {
    top: 8px;
    right: -3px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    top: 35px;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    width: 400px;
    border-radius: 5px;
    padding: 15px;
  }

  .cart-container:hover .dropdown-content,
  .dropdown-content:hover {
    display: block;
  }

  .dropdown-item {
    color: inherit;
    text-decoration: none;
    display: block;
    padding: 5px 5px;
    border-radius: 10px;
  }

  .dropdown-item:hover {
    background-color: #f1f1f1;
  }

  .item-cart-title {
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0px 5px;
  }
</style>
